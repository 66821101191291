<template>
    <div class="Fundingsupport">
        <div class="Addfunds">
            <el-button size="mini" type="primary" @click="dialogVisible=true">添加日志</el-button>
        </div>
        <el-table 
            :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)" 
            style="width: 100%"
            stripe
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
        >   
            <el-table-column type="index" width="100" label="#"></el-table-column>
            <el-table-column prop="name" label="日志名称" width="300px"></el-table-column>
            <el-table-column prop="attach" label="文件地址">
                <template slot-scope="scope">
                    <a :href="scope.row.attach" target="_blank" class="aaaaa">{{scope.row.attach}}</a>
                </template>
            </el-table-column>
            <el-table-column prop="creatTime" label="上传时间" width="300px"></el-table-column>
            <el-table-column prop="level" label="操作" width="150px">
                <template slot-scope="scope">
                    <el-button @click="deleted(scope.row)" size="mini" type="danger">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-col :span="24">
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-sizes="[10, 15, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableData.length">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
        <!-- 弹窗 -->
        <div class="dulepopup">
            <el-dialog
                title="添加日志"
                :visible.sync="dialogVisible"
                width="580px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei()"></i>
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="130px">
                    <el-form-item label="日志名称" prop="name">
                        <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="上传文件" prop="attach">
                        <el-upload
                            class="upload-demo"
                            :headers="headers"
                            drag
                            :limit='1'
                            :action="this.$store.state.uploadimage"
                            :on-success="handleChange"
                            :file-list="fileList"
                        >
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">只能上传1个日志文件</div>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" size="small" @click="addqued('ruleForm')">确 定</el-button>
                    <el-button size="small" @click="closei()">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        headers() {
            return{
                "Authorization": this.$store.state.token // 直接从本地获取token就行
            }
        }
    },
    data () {
        return {
            tableData:[],
            fileList:[],
            pageSize:10,
            totalnum:0,
            currentPage:1,
            dialogVisible:false,
            ruleForm:{
                name:'',
                attach:'',
            },
            rules:{
                name:[
                    { required: true, message: '请输入日志名称', trigger: 'blur' }
                ],
                attach:[
                    { required: true, message: '请上传文件', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        getapp(){
            this.axios.loglist({
                params:{
                    itemId:this.$store.state.itemId
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.tableData = res.data.rows
                }else{
                    this.tableData  = []
                }
            }).catch(err=>{

            })
        },
        //添加确定
        addqued(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.itemId = this.$store.state.itemId
                    this.axios.logadd(
                        this.ruleForm
                    ).then(res=>{
                        if(res.data.code==200){
                            this.dialogVisible = false
                            this.$refs[formName].resetFields();
                            this.getapp()
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        // 删除
        deleted(rows){
            this.axios.logdelete({
                params:{
                    id:rows.id
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.getapp()
                }
            }).catch(err=>{

            })
        },
        // 上传文件
        handleChange(res,file){
            this.ruleForm.attach = res.url
        },
        handleAvatarSuccess(res, file) {
            this.ruleForm.imageUrl = URL.createObjectURL(file.raw);
        },
        closei(){
            this.dialogVisible = false
        },
        handleClose(done){
            console.log(done)
        },
        handleSizeChange(val){
            this.pageSize = val;
        },
        handleCurrentChange(val){
            this.currentPage = val;
        },
    },
    mounted () {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../assets/css/student/Fundingsupport.css';
</style>
<style>
.aaaaa:hover {
    color: #409EFF;
    cursor: pointer;
}
.Fundingsupport .el-table__body-wrapper{
    min-height: calc(100vh - 340px);
}
.Fundingsupport .el-table__empty-block{
    min-height: calc(100vh - 340px);
}
.dulepopup .el-dialog__header{
    padding: 0 0 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #eaecee;
    background: #409EFF;
}
.dulepopup .el-dialog__title{
    font-size: 14px;
    color: #fff;
}
.dulepopup .el-dialog__footer{
    text-align: center;
}
.dulepopup .el-form-item__content{
    width: 350px;
}
.dulepopup .el-upload__tip, .dulepopup .upload-demo,.dulepopup .el-upload__text{
    line-height: 1;
}
.dulepopup .el-upload-dragger{
    width: 350px;
    height: 150px;
}
</style>